<template>
    <div class="doctor-login" :style="{ backgroundImage: 'url(' + require('@/assets/img/doctor-bg.jpg') + ')' }">
        <b-sidebar bg-variant="secondary" :no-close-on-backdrop="true" :no-close-on-esc="true" id="sidebar-no-header" :visible="signIn" aria-labelledby="sidebar-no-header-title" no-header shadow width="375px">
            <template #default="{ }">
                <div class="p-3">
                    <div class="text-center">
                        <img :src="imgUrl+clinic.logo" class="navbar-brand-img" alt="..." v-if="clinic.logo&&clinic.logo!=''">
                        <img src="@/assets/img/logo.png" class="navbar-brand-img" alt="..." v-else>
                    </div>
                    <h4 class="text-center">{{$t('general.login_your_account')}}</h4>
                    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                        <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                            <base-input alternative class="mb-3" name="email" :nameAs="$t('profile.email')" :rules="{required: true, email: true}" prepend-icon="fas fa-envelope" :placeholder="$t('profile.email')" v-model="model.email">
                            </base-input>

                            <div class="password-div">
                                <base-input alternative class="mb-3" name="password" :nameAs="$t('profile.password')" :rules="{required: true, min: 6}" prepend-icon="fas fa-unlock-alt" :type="showPassword?'text':'password'" :placeholder="$t('profile.password')" v-model="model.password">
                                </base-input>
                                <div class="showPassword" @click="showPass">
                                    <span v-if="!showPassword">
                                        <i class="fas fa-eye"></i>
                                    </span>
                                    <span v-else>
                                        <i class="fas fa-eye-slash"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="text-center">
                                <base-button type="primary" block native-type="submit" class="my-4">{{$t('general.login')}}</base-button>
                                <base-button type="link text-primary" @click.prevent="forgetForm">{{$t('general.forger_password')}}</base-button>
                            </div>
                        </b-form>
                    </validation-observer>
                </div>
                <div class="text-center">
                    <small>{{$t('general.powered_by_text')}} @ {{new Date().getFullYear()}}</small>
                </div>
            </template>
        </b-sidebar>
        <b-sidebar bg-variant="secondary" :no-close-on-backdrop="true" :no-close-on-esc="true" id="sidebar-no-header" :visible="forgetPassword" aria-labelledby="sidebar-no-header-title" no-header shadow width="375px">
            <template #default="{ }">
                <div class="p-3" v-loading="loading">
                    <div class="text-center">
                        <img :src="imgUrl+clinic.logo" class="navbar-brand-img" alt="..." v-if="clinic.logo&&clinic.logo!=''">
                        <img src="@/assets/img/logo.png" class="navbar-brand-img" alt="..." v-else>
                    </div>
                    <h4 class="text-center">{{$t('general.forgot_password')}}</h4>
                    <validation-observer v-slot="{handleSubmit, invalid}" ref="formValidator">
                        <b-form role="form" @submit.prevent="handleSubmit(onForget)">
                            <base-input alternative class="mb-3" name="email" :nameAs="$t('profile.email')" :rules="{required: true, email: true}" prepend-icon="fas fa-envelope" :placeholder="$t('profile.email')" v-model="forgetData.email">
                            </base-input>

                            <div class="text-center">
                                <base-button type="primary" block native-type="submit" class="my-4" :disabled="invalid">{{$t('general.reset_pass')}}</base-button>
                            </div>
                        </b-form>
                    </validation-observer>
                    <hr>
                    <div class="text-center">
                        <small>
                            <span>{{$t('general.have_account')}} <br>
                                <a href="#" @click.prevent="signInForm" class="link text-primary">{{$t('general.signin')}}!</a>
                            </span>
                        </small>
                    </div>
                </div>
                <div class="text-center">
                    <small>{{$t('general.powered_by_text')}} @ {{new Date().getFullYear()}}</small>
                </div>
            </template>
        </b-sidebar>
        <b-sidebar bg-variant="secondary" :no-close-on-backdrop="true" :no-close-on-esc="true" id="sidebar-no-header" aria-labelledby="sidebar-no-header-title" no-header shadow width="375px" :visible="resetPassword">
            <template #default="{ }">
                <div class="p-3">
                    <div class="text-center">
                        <img :src="imgUrl+clinic.logo" class="navbar-brand-img" alt="..." v-if="clinic.logo&&clinic.logo!=''">
                        <img src="@/assets/img/logo.png" class="navbar-brand-img" alt="..." v-else>
                    </div>
                    <template v-if="!resetPassData.error">
                        <h4 class="text-center">{{$t('general.reset_your_password')}}</h4>
                        <validation-observer v-slot="{handleSubmit, valid}" ref="formValidator">
                            <b-form role="form" @submit.prevent="handleSubmit(onChangePass)">
                                <div class="password-div">
                                    <base-input alternative class="mb-3" name="password" :nameAs="$t('profile.password')" :rules="{required: true, min: 6, confirmed:'confirmation'}" prepend-icon="fas fa-unlock-alt" :type="showPasswordRemember.first?'text':'password'" :placeholder="$t('profile.password')" v-model="resetPassData.password">
                                    </base-input>
                                    <div class="showPassword" @click="showPassRemember('first')">
                                        <span v-if="!showPasswordRemember.first">
                                            <i class="fas fa-eye"></i>
                                        </span>
                                        <span v-else>
                                            <i class="fas fa-eye-slash"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="password-div">
                                    <base-input alternative class="mb-3" name="passwordRepeat" :nameAs="$t('profile.password_repeat')" :rules="{required: true, min: 6}" prepend-icon="fas fa-unlock-alt" :type="showPasswordRemember.second?'text':'password'" :placeholder="$t('profile.password_repeat')" vid="confirmation" v-model="resetPassData.passwordRepeat">
                                    </base-input>
                                    <div class="showPassword" @click="showPassRemember('second')">
                                        <span v-if="!showPasswordRemember.second">
                                            <i class="fas fa-eye"></i>
                                        </span>
                                        <span v-else>
                                            <i class="fas fa-eye-slash"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <base-button type="primary" block native-type="submit" class="my-4" :disabled="!valid || resetPassData.password!=resetPassData.passwordRepeat">{{$t('general.change_pass')}}</base-button>
                                </div>
                            </b-form>
                        </validation-observer>
                        <hr>
                        <div class="text-center">
                            <small>
                                <span>
                                    <a href="#" @click.prevent="signInForm" class="link text-primary">{{$t('general.signin')}}!</a>
                                </span>
                            </small>
                        </div>
                    </template>
                    <template v-else>
                        <b-alert variant="warning" show>{{$t('error.invalid_reset_code')}}</b-alert>
                        <base-button type="link text-primary" @click.prevent="forgetForm">{{$t('general.forger_password')}}</base-button>
                    </template>
                </div>
                <div class="text-center">
                    <small>{{$t('general.powered_by_text')}} @ {{new Date().getFullYear()}}</small>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>
<script>
import { AUTH_REQUEST, AUTH_FORGET, AUTH_CHANGEPASS } from '@/store/modules/define';
import { handleError } from '@/utils/func';
import { setCookie, getCookie } from '@/utils/cookie';
import { mapGetters } from 'vuex';
import 'bootstrap-vue/dist/bootstrap-vue.css';
let self;
export default {
    computed: {
        ...mapGetters({
            clinic: 'getClinic',
        })
    },
    data() {
        return {
            imgUrl: process.env.VUE_APP_CDNURL + '/clinic/',
            signIn: true,
            signUp: false,
            forgetPassword: false,
            resetPassword: false,
            loading: false,
            model: {
                email: '',
                password: '',
                type: 'doctor'
            },
            forgetData: {
                email: '',
                type: 'doctor'
            },
            resetPassData: {
                uuid: '',
                password: '',
                passwordRepeat: '',
                type: 'doctor',
                error: false
            },
            showPassword: false,
            showPasswordRemember: {
                first: false,
                second: false
            }
        };
    },
    methods: {
        showPassRemember(id){
            this.showPasswordRemember[id] = this.showPasswordRemember[id] !== true;
        },
        showPass() {
            this.showPassword = this.showPassword !== true;
        },
        signUpForm() {
            self.resetPassword = false;
            self.forgetPassword = false;
            self.signIn = false;
            self.signUp = true;
        },
        signInForm() {
            self.resetPassword = false;
            self.forgetPassword = false;
            self.signUp = false;
            self.signIn = true;
        },
        forgetForm() {
            self.resetPassword = false;
            self.signUp = false;
            self.signIn = false;
            self.forgetPassword = true;
        },
        resetPassForm() {
            self.signUp = false;
            self.signIn = false;
            self.forgetPassword = false;
            self.resetPassword = true;
        },
        onSubmit() {
            this.loading = true;

            const clinic = getCookie('user-clinic');
            if(clinic){
                this.model.clinic = clinic;
            }

            this.$store
                .dispatch(AUTH_REQUEST, this.model)
                .then(resp => {
                    this.$router.push('/');
                })
                .catch(err => {
                    this.loading = false;
                    handleError(this, err);
                });
        },
        onForget() {
            self.loading = true;
            self.$store
                .dispatch(AUTH_FORGET, self.forgetData)
                .then(resp => {
                    if (resp) {
                        self.$notify({ type: 'success', message: self.$i18n.t('success.password_reset_link_send') });
                    }
                    self.loading = false;
                    self.forgetData.email = '';
                    self.signInForm();
                })
                .catch(err => {
                    self.loading = false;
                    handleError(self, err);
                });
        },
        onChangePass() {
            self.loading = true;
            self.$store
                .dispatch(AUTH_CHANGEPASS, self.resetPassData)
                .then(resp => {
                    if (resp) {
                        self.$notify({ type: 'success', message: self.$i18n.t('success.password_reset') });
                    }
                    location.href = '/doctor-login';
                })
                .catch(err => {
                    self.loading = false;
                    handleError(self, err);
                });
        }
    },
    mounted() {
        self = this;
        setCookie('user_login_type', 'doctor', '1y');
        if (self.$route.name == 'doctorforget') {
            if (self.$route.query.code) {
                this.resetPassData.uuid = self.$route.query.code;
            } else {
                self.resetPassData.error = true;
            }
            self.resetPassForm();
        } else {
            self.signInForm();
        }
    }
};
</script>
